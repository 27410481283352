import React, { Fragment, useCallback, useEffect, useContext } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import {
	VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import {
	PageSegmentLoadEnd,
	SSRMouseEventWrapper,
	SSR_RESTRICTIONS_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';
import { getMark } from '@confluence/performance';
import { RoutesContext } from '@confluence/route-manager';
// TODO(brizvash): allow color and size changes for icons
import { useSubmitSSRScriptErrors } from '@confluence/ssr-scripts-utils';
import { usePageRestrictionsContext, useContentId } from '@confluence/page-restrictions-context';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { fg } from '@confluence/feature-gating';

import { RESTRICTIONS_BUTTON_METRIC } from '../perf.config';
import { RestrictionsDataContainer } from '../RestrictionsDialog/RestrictionsDataContainer';

import { RestrictionsButtonPlaceholder } from './RestrictionsButtonPlaceholder';
import { RestrictionButtonComponent } from './RestrictionsButtonComponent';

const restrictionButtonContainerStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	button: {
		font: token('font.body'),
	},
});

const hiddenStyles = css({ display: 'none' });

export const RestrictionsButton = (props: RestrictionsButtonProps) => {
	const {
		className,
		onClick,
		isDisabled,
		shouldHideSpinner,
		resetDialogState,
		restrictionsButtonSSRRendered = false,
		restrictionsButtonSSRLocation = '',
		triggeredFrom = '',
	} = props;
	const contentId = useContentId(props.contentId);

	const { isExternalCollaborator: isGuest } = useIsExternalCollaborator();

	useSubmitSSRScriptErrors('restrictions-button');

	const isRestrictionsButtonSSRRendered: boolean = Boolean(
		window.__SSR_RENDERED__ && restrictionsButtonSSRRendered,
	);

	const isRestrictionsButtonClickedInSSR = (): boolean => {
		return Boolean(
			window.__SSR_EVENTS_CAPTURE__?.restrictionsButton &&
				(window.__SSR_RENDERED__ || process.env.REACT_SSR) &&
				isRestrictionsButtonSSRRendered,
		);
	};

	const restrictionsButtonClicked = useCallback(
		(
			evt: any,
			restrictionsDataContainer: RestrictionsDataContainer,
			hasRestrictions: boolean,
			hasInheritedRestrictions: boolean,
			hasDirectViewRestrictions: boolean,
		) => {
			if (isRestrictionsButtonSSRRendered && !fg('confluence_ssr_remove_action_button_clicks')) {
				if (!window.__SSR_EVENTS_CAPTURE__) {
					window.__SSR_EVENTS_CAPTURE__ = {};
				}

				window.__SSR_EVENTS_CAPTURE__.restrictionsButton = evt;
			}

			restrictionsDataContainer.setData({
				hasRestrictions,
				hasInheritedRestrictions,
				hasDirectViewRestrictions,
			});

			onClick();
		},
		[isRestrictionsButtonSSRRendered, onClick],
	);

	const { transitionId } = useContext(RoutesContext);

	useEffect(() => {
		if (resetDialogState && transitionId) {
			resetDialogState();
			if (window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton) {
				delete window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transitionId]);

	useEffect(() => {
		if (isRestrictionsButtonClickedInSSR()) {
			onClick();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			shouldHideSpinner &&
			window.__SSR_EVENTS_CAPTURE__ &&
			window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton
		) {
			delete window.__SSR_EVENTS_CAPTURE__?.restrictionsButton;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldHideSpinner]);

	useEffect(() => {
		if (isRestrictionsButtonSSRRendered) {
			//set FMP now if SSR rendered for default restrictions dialog
			RESTRICTIONS_BUTTON_METRIC.markFMP(getMark('CFP-63.ssr-ttr'));
		}
	}, [isRestrictionsButtonSSRRendered]);

	const restrictionButtonGenerator = useCallback(
		(
			restrictionsDataContainer: RestrictionsDataContainer,
			hasDirectViewRestrictions: boolean,
			hasInheritedViewRestrictions: boolean,
			hasRestrictions: boolean,
		) => {
			return (
				<Fragment>
					{process.env.REACT_SSR && (
						<SSRActionLoadingSpinner
							spinnerId="restrictions-loading-spinner"
							actionType="restrictionsButton"
						/>
					)}
					<SSRMouseEventWrapper metricName={SSR_RESTRICTIONS_BUTTON_METRIC}>
						<span
							css={restrictionButtonContainerStyle}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={className || ''}
							data-testid="restriction-button"
						>
							<RestrictionButtonComponent
								hasDirectViewRestrictions={hasDirectViewRestrictions}
								hasInheritedViewRestrictions={hasInheritedViewRestrictions}
								hasRestrictions={hasRestrictions}
								onClick={(evt) =>
									restrictionsButtonClicked(
										evt,
										restrictionsDataContainer,
										hasDirectViewRestrictions,
										hasInheritedViewRestrictions,
										hasRestrictions,
									)
								}
								isDisabled={isDisabled}
							/>
						</span>
						<ExperienceSuccess name={VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE} />
						<PageSegmentLoadEnd
							key={`end-${contentId}`}
							metric={RESTRICTIONS_BUTTON_METRIC}
							customData={{
								restrictionsButtonSSRRendered,
								restrictionsButtonSSRLocation,
								triggeredFrom,
							}}
						/>
					</SSRMouseEventWrapper>
				</Fragment>
			);
		},
		[
			className,
			restrictionsButtonClicked,
			isDisabled,
			contentId,
			restrictionsButtonSSRRendered,
			restrictionsButtonSSRLocation,
			triggeredFrom,
		],
	);

	const { restrictions, loading } = usePageRestrictionsContext();

	const isQueryLoading = !restrictions && loading;

	if (!contentId) return null;
	return (
		<Subscribe to={[RestrictionsDataContainer]}>
			{(restrictionsDataContainer: RestrictionsDataContainer) => {
				if (isRestrictionsButtonClickedInSSR() && shouldHideSpinner === false) {
					return (
						<SSRActionLoadingSpinner
							spinnerId="restrictions-loading-spinner"
							actionType="restrictionsButton"
						/>
					);
				}
				if (isQueryLoading || isGuest) {
					return (
						// We are using this container as a hacky way to hide the button when the user is a Guest (External
						// Collaborator).  Normally we would just not render the component if we wanted to hide it, but in
						// Fabric editor this button is rendered by a function passed as a prop to the AkEditor control,
						// and the AkEditor will throw an error if the function doesn't render anything. So instead we will
						// render the placeholder but hide it via CSS if the user is a Guest.  This makes the AkEditor
						// happy while not showing the button.
						<div css={isGuest && hiddenStyles} data-test-id="restriction-placeholder-container">
							<RestrictionsButtonPlaceholder />
						</div>
					);
				}

				const hasDirectViewRestrictions = Boolean(restrictions?.hasViewRestrictions);
				const hasInheritedRestrictions = Boolean(restrictions?.hasInheritedRestrictions);
				const hasRestrictions = Boolean(restrictions?.hasRestrictions);
				return restrictionButtonGenerator(
					restrictionsDataContainer,
					hasDirectViewRestrictions,
					hasInheritedRestrictions,
					hasRestrictions,
				);
			}}
		</Subscribe>
	);
};

type RestrictionsButtonProps = {
	className?: string;
	contentId?: string;
	onClick(): void;
	resetDialogState?(): void;
	isDisabled?: boolean;
	shouldHideSpinner?: boolean;
	restrictionsButtonSSRRendered?: boolean;
	restrictionsButtonSSRLocation?: string;
	triggeredFrom?: string;
};
