/* eslint-disable @atlaskit/design-system/no-custom-icons */
/* This custom icon is ignored due to ongoing migration. It will be removed when migration is complete. */
import React from 'react';

import Icon from '@atlaskit/icon/base';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';

// DSP-13954 - Functional / UnlockedIcon
// The regular unlocked icon has a very small gap in the clasp that communicates
// much too subtly that it's unlocked. This is an exaggerated padlock where the
// clasp is turned all the way to the side to make the point.
const UnlockedIconGlyph = (props: CustomGlyphProps) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24">
		<g fill="none" fillRule="evenodd">
			<path opacity=".5" d="M0 0h24v24H0z" />
			<path
				d="M8 10V8a2 2 0 10-4 0v3H2V8a4 4 0 118 0v2h6a2 2 0 012 2v6a2 2 0 01-2 2H8a2 2 0 01-2-2v-6c0-1.1.9-2 2-2zm4 7a2 2 0 100-4 2 2 0 000 4z"
				fill="currentColor"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

export const UnlockedIcon = (props: IconProps) => <Icon glyph={UnlockedIconGlyph} {...props} />;
